*,
*::before,
*::after {
  position: relative;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

html {
  background: #000000;
  color: #eeeeee;
  overflow: auto;
  scroll-behavior: smooth;
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
}

.slides {
  display: grid;
  > .slide {
    grid-area: 1 / -1;
  }

  > button {
    appearance: none;
    background: transparent;
    border: none;
    position: absolute;
    width: 20rem;
    height: 40rem;
    outline: none;

    z-index: 5;

    &:first-child {
      left: -50%;
    }
    &:last-child {
      right: -50%;
    }
  }
}

.slideContent {
  width: 50vw;
  height: 40vw;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  transition: all 0.5s ease-in-out;
  opacity: 0.5;
  filter: blur(10px);
  -webkit-filter: blur(10px);

  display: grid;
  align-content: center;

  transform-style: preserve-3d;
  transform: perspective(1000px) translateX(calc(80% * var(--offset)))
    rotateY(calc(45deg * var(--offset)));
}

.slideContentInner {
  text-align: center;
  width: auto;
  text-shadow: 0 0.1rem 1rem #000;
  opacity: 0;

  .slideTitle {
    font-size: 2rem;
    font-weight: normal;
    letter-spacing: 0.2ch;
    margin: 0;
  }

  .slideDesc {
    top: 20vw;
    white-space: pre-line;
  }
}

.slideBackground {
  position: fixed;
  top: 0;
  bottom: 0;
  left: -10%;
  right: -10%;
  background-size: cover;
  background-position: center center;
  z-index: -1;
  opacity: 0;
  filter: blur(10px);
  -webkit-filter: blur(10px);
}

.slide[focus="0"] {
  .slideBackground {
    opacity: 0.2;
  }

  .slideContentInner {
    opacity: 1;
  }

  .slideContent {
    border: 8px solid #eee;
    filter: none;
    -webkit-filter: none;
    opacity: 1;
  }
}
